import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ValidationMessages } from '../validation-messages';
import { catchError, finalize } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class QuoteUnderwritingService {

  apiURL = "";

  //Aborting previous same api request
  private abortController = new AbortController();
  constructor(private httpClient: HttpClient) {     
      var environmentt: string;
      var appUrl: string;
      environmentt = window.location.hostname;
      switch (environmentt) {
        case 'localhost':
          appUrl = 'http://localhost:8000/api';
          break;
        case (ValidationMessages.productionUrl):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionUrlWithWww):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionNormandyDomain):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionCanopyDomain):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionPbibinsDomain):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        default:
          appUrl = ValidationMessages.stagingUrl + '/api';
      }
      this.apiURL = appUrl;    
      
  }

  randomString(length) {
    var text = "";
    var possible = 'abcdefghijklmnopqrstuvwxyz'
    +'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    +'0123456789!@#$%^&*()';
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

  getNewToken(){
    let localStToken = localStorage.getItem('loggedUserToken');
    if(localStToken == "" || localStToken == null || localStToken == "null" || typeof localStToken == undefined ){
      localStToken = 'T0dWQlFIRm9lREZIWWpZNFV6a2tVVUpVVEdnMWMxWTNaakJyV1V0cFFYTndKbEpPVmpCbVF6Tm9ZWFU9';
      localStorage.setItem('loggedInMbId','ff682d71fbf6b8581a0fc91f282bae37');
    }
    let decryptToken = atob(atob(btoa(atob(localStToken))));
    let currentIntValue =  parseInt(decryptToken.charAt(7)+''+decryptToken.charAt(25)+''+decryptToken.charAt(37));
    let nextIntValue = 100;
    if(currentIntValue < 999){
      nextIntValue = currentIntValue+1;
    }
    let token = this.randomString(7)+''+nextIntValue.toString().charAt(0)+''+this.randomString(17)+''+nextIntValue.toString().charAt(1)+''+this.randomString(11)+''+nextIntValue.toString().charAt(2)+''+this.randomString(6);
    let encryptToken = btoa(btoa(atob(btoa(token))));
    localStorage.setItem('loggedUserToken', encryptToken);
    return encryptToken;
  }

  public updateQuoteStatus(postData: any): Observable<any>
  {    
    // Abort the previous request
    this.abortController.abort();
    // Create a new AbortController
    this.abortController = new AbortController();

    const url = `${this.apiURL}/quote-status`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    // return this.httpClient.post(url, JSON.stringify(postData), options);

    let signalContrlObj = { abort_signal: this.abortController.signal};
    let newPostData:any   = JSON.stringify({...postData,...signalContrlObj});

    return this.httpClient.post(url, newPostData , options).pipe(
      catchError(error => {
        if (error.name === 'AbortError') {
          console.log('Request was aborted');
        } else {
          console.error('An error occurred', error);
        }
        return [];
      }),
      finalize(() => {
        // Cleanup after request is completed or aborted
        this.abortController = new AbortController();
      })
    );
  }
  public isMGAApproveForBroker(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/sned-mail-to-mga-approve-broker`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public endorsementApprodedDocs(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/send-end-aaproved-pdf`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public updateQuoteRenewalStatus(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/update-quote-renewal-status`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public excEndDesc(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/excess-end-desc`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public deleteEndmnt(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/delete-quote-endrsmnt`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public apprGLEndt( type:number,delLtApprObj:any,qbe_cron_appr_by:any = 1 ): Observable<any>
  {
    let url = `${this.apiURL}/daily-crons/4*1*${delLtApprObj.qbe_qu_id}*${delLtApprObj.qbe_endorsement_type}*${qbe_cron_appr_by}`;
    if( delLtApprObj != undefined && delLtApprObj != null && delLtApprObj.qbe_cron_approval != undefined && delLtApprObj.qbe_cron_approval != null )
    {
      if( delLtApprObj.qbe_cron_approval == 5 )
      {
        let sameLimitZero = "sameLimitZero";
        url = `${this.apiURL}/daily-crons/4*1*${delLtApprObj.qbe_qu_id}*${sameLimitZero}`;
      }
      else if( delLtApprObj.qbe_cron_approval == 6 )
      {
        let sameLimitManual = "sameLimitManual";
        url = `${this.apiURL}/daily-crons/4*1*${delLtApprObj.qbe_qu_id}*${sameLimitManual}`;
      }
    }
    
  	let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public excChgDelete(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/exc-change-delete`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public glCancDelete(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/gl-canc-delete`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public glReinDelete(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/gl-rein-delete`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public excCancDelete(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/exc-canc-delete`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public excReinDelete(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/exc-rein-delete`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public delReqExc(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/delete-request-excess`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public reqExcHold(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/hold-request-excess`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public setNewRenewalSubmit(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/new-renewal-submit`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public setNewRenewalDecl(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/new-renewal-decline`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public checkPolicyDetails(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/qr-check-policy`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public deleteRenewalSubmit(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/delete-renewal-submit`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public eSignAPI(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/esign-record`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public imuStatusUpdate(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/imu-status-updte`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  //renwalQuote status change
  public imuRenwalStatusUpdate(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/imu-renwal-status-updte`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public imuNewCopyQuote(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/imu-new-copy-quote`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public cancelEsignDocument(qu_id:any){
    const url = `${this.apiURL}/cancel-document/${qu_id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public mailsUpdate(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/esign-mails-update`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  
  // Update Payment Option
  public paymentOptionsUpdate(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/update-payment-option`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  
  // back to forward to insured
  public backtoforwardtoinsured(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/back-to-forward-to-insured`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  // End

  public updateUnderwritingInfo(postData: any){    
    const url = `${this.apiURL}/quote-underwriting-info`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public getUnderwritingInfo(qu_id:any): Observable<any>{
    const url = `${this.apiURL}/quote-underwriting-info/show/${qu_id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getQuoteUploads(qu_id:any, userID:any, status:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');
    
    var loggedUserRole = localStorage.getItem('u_ur_id');
    const url = `${this.apiURL}/quote-history/show/${qu_id}-${userID}-${loggedUserRole}-${status}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getTimestams(): Observable<any>{
    var loggedUserRole = localStorage.getItem('u_ur_id');
    const url = `${this.apiURL}/get-timeStamp`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getAuditQuoteUploads(qu_id:any, userID:any, status:any,audit_id:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    var type = 1;
    var loggedUserRole = localStorage.getItem('u_ur_id');
    const url = `${this.apiURL}/quote-history/show/${qu_id}-${userID}-${loggedUserRole}-${status}-${audit_id}-${type}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public getInspectionQuoteUploads(qu_id:any, userID:any, status:any,inspection_id:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    var type = 2;
    var loggedUserRole = localStorage.getItem('u_ur_id');
    const url = `${this.apiURL}/quote-history/show/${qu_id}-${userID}-${loggedUserRole}-${status}-${inspection_id}-${type}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public deleteQuoteDocs(postData: any): Observable<any>{
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/quote-history`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public getAllClaims(qu_id:any, userID:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/quote-claims/show/${qu_id}-${userID}-1-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public requestForClaim(qu_id:any, userID:any): Observable<any>{
    const url = `${this.apiURL}/quote-claims/show/${qu_id}-${userID}-2`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  // send mail request
  public requestMailForClims(postData:any): Observable<any>{
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/request-sent-mail-clims`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public requestForAudit(qu_id:any, userID:any): Observable<any>{
    const url = `${this.apiURL}/quote-audits/show/${qu_id}-${userID}-2`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  // send mail request
  public requestMailForAudit(postData:any): Observable<any>{
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/request-sent-mail-audit`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  // get Quote Information
  public getQuoteInformation(postData:any): Observable<any>{
    const url = `${this.apiURL}/get-quote-info`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public statusChangeClaim(status:any, id:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/quote-claims/show/${status}-${id}-3-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getClaim(claim_id:any,type:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/quote-claims/show/${claim_id}-0-${type}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getClaimPdf(claim_id:any, loggedUserRole:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/claim-pdf/${claim_id}-${loggedUserRole}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  

  public getAudit(claim_id:any,type:any): Observable<any>{
    const url = `${this.apiURL}/quote-audits/show/${claim_id}-0-${type}-${localStorage.getItem('u_id')}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getNewClaim(claim_id:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/quote-claims/show/${claim_id}-0-5-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getNewAudit(claim_id:any): Observable<any>{
    const url = `${this.apiURL}/quote-audits/show/${claim_id}-0-5-${localStorage.getItem('u_id')}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public saveClaimsForm(postData: any): Observable<any>{
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/quote-claims`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public saveAuditsForm(postData: any): Observable<any>{
    const url = `${this.apiURL}/quote-audits`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public closeEmailAuditsForm(postData: any): Observable<any>{
    const url = `${this.apiURL}/save-audit-form`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public getQuoteSummaryMain(id:any): Observable<any>{
    let roleUser = localStorage.getItem('u_ur_id');
    let userId = localStorage.getItem('u_id');
    const url = `${this.apiURL}/get-quote-summary-main/${id}-${roleUser}-${userId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getReGenEndGLPdf(id:any, quoteId:any): Observable<any>{
    const url = `${this.apiURL}/gen-cov-docs/${id}-${quoteId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public getReGenEndExcPdf(id:any, quoteId:any): Observable<any>{
    const url = `${this.apiURL}/gen-ex-chng-docs/${id}-${quoteId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getQuoteSummaryInsuredInfo(id:any): Observable<any>{
    let roleUser = localStorage.getItem('u_ur_id');
    let userId = localStorage.getItem('u_id');
    const url = `${this.apiURL}/get-quote-summary-insured-info/${id}-${roleUser}-${userId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getQuoteSummary(id:any): Observable<any>{
    let roleUser = localStorage.getItem('u_ur_id');
    let userId = localStorage.getItem('u_id');
    const url = `${this.apiURL}/get-summary/show/${id}-${roleUser}-${userId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getQuoteSummaryClaimsAuditsInspections(id:any): Observable<any>{
    let roleUser = localStorage.getItem('u_ur_id');
    let userId = localStorage.getItem('u_id');
    const url = `${this.apiURL}/get-quote-summary-claims-audits-inspections/${id}-${roleUser}-${userId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getQuoteSummaryFileNotes(id:any): Observable<any>{
    let roleUser = localStorage.getItem('u_ur_id');
    let userId = localStorage.getItem('u_id');
    const url = `${this.apiURL}/get-quote-summary-file-notes/${id}-${roleUser}-${userId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public getQuoteSummaryPdfDocuments(id:any): Observable<any>{
    let roleUser = localStorage.getItem('u_ur_id');
    let userId = localStorage.getItem('u_id');
    const url = `${this.apiURL}/get-quote-summary-pdf-documents/${id}-${roleUser}-${userId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public updateActivityStatus(postData: any): Observable<any>{
    const url = `${this.apiURL}/update-activity-status`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public postQuoteSummary(postData: any): Observable<any>{
    const url = `${this.apiURL}/post-summary`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public saveNotification(postData: any): Observable<any>{
    const url = `${this.apiURL}/save-notification`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public printDocuments(id:any, state:any, type:any, iso:any, openroopiso:any, loginRole:any=0): Observable<any>{
    var url = "";
    if(type == 1){
      url = `${this.apiURL}/quote-app-pdf/show/${id}`;
    }else if(type == 2 && state != 'AZ' && state != 'OH' && iso == 0 && openroopiso == 0 ){
      url = `${this.apiURL}/policy-pdf/${id}-${loginRole}`;
    }else if(type == 2 && state == 'AZ' && iso == 0 && openroopiso == 0 ){
      url = `${this.apiURL}/policy-az/show/${id}-${loginRole}`;
    }else if(type == 2 && state == 'OH' && iso == 0 && openroopiso == 0 ){
      url = `${this.apiURL}/policy-oh/show/${id}-${loginRole}`;
    }else if(type == 2 && state != 'AZ' && state != 'OH' && (iso == 1 || openroopiso == 1) ){
      url = `${this.apiURL}/policy-pdf-iso/${id}-${loginRole}`;
    }else if(type == 2 && state == 'AZ' && (iso == 1 || openroopiso == 1) ){
      url = `${this.apiURL}/policy-az-iso/${id}-${loginRole}`;
    }else if(type == 2 && state == 'OH' && (iso == 1 || openroopiso == 1) ){
      url = `${this.apiURL}/policy-oh-iso/${id}-${loginRole}`;
    }else if(type == 3){
      url = `${this.apiURL}/send-documents-nationwide/${id}`;
    }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  
  public printExcessDocuments(id:any, state:any, type:any, iso:any,loginRole:any=0): Observable<any>{
    var url = "";
    if(type == 2 && state != 'AZ' && state != 'OH'){
      url = `${this.apiURL}/policy-xls/${id}-${loginRole}`;
    }else if(type == 2 && state == 'AZ'){
      url = `${this.apiURL}/policy-xli/show/${id}-${loginRole}`;
    }else if(type == 2 && state == 'OH'){
      url = `${this.apiURL}/policy-xlh/show/${id}-${loginRole}`;
    }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public printWcDocuments(id:any): Observable<any>{
    let url = "";
    url = `${this.apiURL}/wc-genarate-pdf/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public printEoDocuments(id: any,userrole: any,type:any = 1){
    let url = "";
    url = `${this.apiURL}/co-genarate-pdf/${id}-${userrole}-${type}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get<any>(url, options);
  }

  public printBrDocuments(id:any): Observable<any>{
    let url = "";
    url = `${this.apiURL}/builder-risk-form-get/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public printIMUDocuments(id: any, type:any = 1){
    let url = "";
    url = `${this.apiURL}/genrate-imu-policy-doc/${id}-${type}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get<any>(url, options);
  }

  public getAllAIPDFs(id:any): Observable<any>{
    let url = "";
    url = `${this.apiURL}/get-all-aI-pdfs/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public printGLaddQustDocuments(id:any,aqNum:any=0): Observable<any>{
    let url = "";
    url = `${this.apiURL}/get-endorsement-ai-pdf/${id}-${aqNum}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public attchWcDocuments(id:any): Observable<any>{
    let url = "";
    url = `${this.apiURL}/wc-genarate-pdf/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public printHDocuments(id:any, type:any, loginRole:any=0): Observable<any>{
    let url = "";
    var loggedUserId = localStorage.getItem('u_id');
    var wholesaler = localStorage.getItem('wholesaler');
    var wholesalerAgentQuote = localStorage.getItem('wholesaler_agent_quote');

    if(type == 1){
      url = `${this.apiURL}/invoice-pdf/show/${id}-${loginRole}-${loggedUserId}`;
    }
    else if(type == 12){
      url = `${this.apiURL}/invoice-pdf/show/${id}-${loginRole}-${loggedUserId}-${wholesaler}-${wholesalerAgentQuote}`;
    }
    else if(type == 11){
      url = `${this.apiURL}/invoice-pdf-withoutcomission/${id}`;
    }else if(type == 2){
      url = `${this.apiURL}/rating-pdf/${id}`;
    }else if(type == 3){
      url = `${this.apiURL}/application-pdf/${id}`;
    }else if(type == 4){
      url = `${this.apiURL}/sl-one-pdf/${id}-${loginRole}`;
    }else if(type == 5){
      url = `${this.apiURL}/sl-two-pdf/${id}-${loginRole}`;
    }else if(type == 6){
      url = `${this.apiURL}/finance-agreement-pdf/${id}`;
    }else if(type == 7){
      url = `${this.apiURL}/quote-package-pdf/${id}-${loginRole}`;
    }else if(type == 8){
      url = `${this.apiURL}/endorsement-pdf/${id}`;
    }else if(type == 9){
      url = `${this.apiURL}/pdf-state/${id}-${loginRole}`;
    }else if(type == 10){
      url = `${this.apiURL}/certificates-li/${id}`;
    } else if(type == 'cpplagent'){
      url = `${this.apiURL}/cppl-invoice-agent-pdf/${id}-${loginRole}-${loggedUserId}-${wholesaler}-${wholesalerAgentQuote}`;
    } else if(type == 'cpplinsured'){
      url = `${this.apiURL}/cppl-invoice-pdf-withoutcomission/${id}-${loginRole}-${loggedUserId}`;
    } else if(type == 'cpplinvoice'){
      url = `${this.apiURL}/cppl-invoice-pdf/${id}-${loginRole}-${loggedUserId}`;
    } else if(type == 'lro-rating'){
      url = `${this.apiURL}/lro-rating-pdf/${id}-${loginRole}-${loggedUserId}`;
    } else if(type == 'lro-invoice'){
      url = `${this.apiURL}/lro-invoice-pdf/show/${id}-${loginRole}-${loggedUserId}`;
    }else if(type == 'lro-agent-invoice'){
      url = `${this.apiURL}/lro-invoice-pdf/show/${id}-${loginRole}-${loggedUserId}-${wholesaler}-${wholesalerAgentQuote}`;
    }else if(type ==  'lro-insured-invoice'){
      url = `${this.apiURL}/lro-insured-invoice/${id}`;
    }else if(type ==  'lro-quote-package'){
      url = `${this.apiURL}/lro-quote-package-pdf/${id}-${loginRole}`;
    }else if(type == 'lro-finance'){
      url = `${this.apiURL}/lro-finance-agreement-pdf/${id}`;
    }else if(type == 'lro-policy'){
      url = `${this.apiURL}/lro-quote-policy-pdf/${id}-${loginRole}`;
    }else if(type == 'lro-certificates'){
      url = `${this.apiURL}/lro-certificates-li/${id}`;
    }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  // Open DS Form
  public openDSForm(id:any): Observable<any>{
    let url = "";
    url = `${this.apiURL}/open-ds-form/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  // testing Sign Now API
  public signNowApi(postData: any): Observable<any>{
    const url = `${this.apiURL}/sign-now`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public getOmniFile(postData: any): Observable<any>{
    const url = `${this.apiURL}/get-omni-file`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public printFinanaceAggrementDocument(postData: any): Observable<any>{
    const url = `${this.apiURL}/finanace-aggrement-pdf`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public inspectionPdf(id:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    let url = "";
    url = `${this.apiURL}/inspection-pdf/${id}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public auditPdf(id:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    let url = "";
    url = `${this.apiURL}/audit-pdf/${id}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public downLoadAllDocs(postData:any): Observable<any>{
    const url = `${this.apiURL}/download-zip-documents`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public sendMailToInsBro(postData:any): Observable<any>{
    const url = `${this.apiURL}/sned-mail-to-insurer-broker`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public sendMailToBroNotes(postData:any): Observable<any>{
    const url = `${this.apiURL}/sned-mail-to-broker-notes`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public updategenerateexEndtPdf(postData:any): Observable<any>{
    const url = `${this.apiURL}/update-ex-endorsemet-pdf`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  
  // inspection services
  public saveInspectionForm(postData: any): Observable<any>{
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/quote-inspection`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public closeMailsaveInspectionForm(postData: any): Observable<any>{
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/save-inspection`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public getInspection(claim_id:any,type:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/quote-inspection/show/${claim_id}-0-${type}-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public newgetInspection(claim_id:any): Observable<any>{
    let accessToken = this.getNewToken();
    let token_unique_id = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/quote-inspection/show/${claim_id}-0-5-${token_unique_id}-${accessToken}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public requestForInspection(qu_id:any, userID:any): Observable<any>{
    const url = `${this.apiURL}/quote-inspection/show/${qu_id}-${userID}-2`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public addNewInspectionFormPdf(postData: any): Observable<any>{
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/get-inspection-addNew-pdf`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  //mail send
  public requestMailForInspection(postData:any): Observable<any>{
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/request-sent-mail-inspection`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public saveESUplFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/save-es-upl-file`;
    return this.httpClient.post(url, postData);
  }
  public saveManualFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/save-manual-esign-file`;
    return this.httpClient.post(url, postData);
  }
  public saveExcFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/save-exc-file`;
    return this.httpClient.post(url, postData);
  }
  public checkEffctvDate(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/check-effective-date`;
    return this.httpClient.post(url, postData);
  }
  
  public delESFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/delete-es-file`;
    return this.httpClient.post(url, postData);
  }
  
  public checkRenDate( postData: any ): Observable<any>
  {    
    const url = `${this.apiURL}/check-ren-date`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public yearChangeCheck( postData: any ): Observable<any>
  {    
    const url = `${this.apiURL}/check-date-effective`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public excPremOveralRateCal( postData: any ): Observable<any>
  {    
    const url = `${this.apiURL}/exc-overalratecal`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  
  public printAuditDocuments(id:any, type:any,mode:number = 0): Observable<any>{
    let url = `${this.apiURL}/audit-invoice-pdf/${id}-${mode}`;
    if(type == 2){
      url = `${this.apiURL}/audit-endorsement-pdf/${id}-${mode}`;
    }else if(type == 3){
      url = `${this.apiURL}/audit-invoice-pdf/${id}-${mode}-1`;
    }else if(type == 4){
      url = `${this.apiURL}/audit-invoice-pdf/${id}-${mode}-0-1`;
    }
    else if(type == 5){
      url = `${this.apiURL}/audit-invoice-pdf/${id}-${mode}-1-1`;
    }
    else if(type == 6){
      url = `${this.apiURL}/audit-rating-pdf/${id}-${mode}`;
    }    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  // completed genarate pdf
  public printCompletedAuditDocuments(postData: any, type:any): Observable<any>{
    let url = `${this.apiURL}/audit-completed-invoice-pdf`;
    if(type == 2){
      url = `${this.apiURL}/audit-completed-endorsement-pdf`;
    }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public genarateAuditEndDocuments(postData: any, type:any): Observable<any>{
    let url = `${this.apiURL}/audit-completed-endorsement-pdf`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public printAuditCompletedDocuments(id:any): Observable<any>{
    let url = `${this.apiURL}/audit-pdf/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public printInspectionReviewedDocuments(id:any): Observable<any>{
    let url = `${this.apiURL}/inspection-pdf/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public addNewAuditFormPdf(postData: any): Observable<any>{
    const url = `${this.apiURL}/get-audit-addNew-pdf`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public auditCloseEvnPdf(postData: any): Observable<any>{
    const url = `${this.apiURL}/get-audit-closed-evn-pdf`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  // addCloseEvnAuditFormPdf
  
  public shMultiUpload(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/save-loss-multiple`;
    return this.httpClient.post(url, postData);
  }
  public delMultiFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/delete-multi-file`;
    return this.httpClient.post(url, postData);
  }


  public genDailyActivityRep(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/gen-web-daily-activity`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public getAllStatesOfQuotes(): Observable<any>
  {    
    let url = `${this.apiURL}/get-all-states-of-quotes/1`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public getAlladmins(): Observable<any>
  {    
    let url = `${this.apiURL}/get-all-admins`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  } 
    public getAllwholesalers(): Observable<any>
  {    
    let url = `${this.apiURL}/get-all-wholesalers`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  
  public genarateIPFSdoc(qu_id:any, status:any): Observable<any>
  {
    const url = `${this.apiURL}/get-IPFS-data/${qu_id}-${status}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public excMailAppr(postData: any): Observable<any>
  {
    // exc-appr-mail
    const url = `${this.apiURL}/exc-appr-mail`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public saveIpfsDoc(postData: any): Observable<any>{
    const url = `${this.apiURL}/save-ipfs-doc`;
    return this.httpClient.post(url, postData);
  }

  public sendRequestToIpfs(postData): Observable<any> {
    const url = `${this.apiURL}/send-request-to-ipfs`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  } 
  public openCertificateData(postData):  Observable<any> {
    const url = `${this.apiURL}/ind-sch-per-proj`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public openBORNotication(postData):  Observable<any> {
    const url = `${this.apiURL}/bor-noti-admin-side`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public getBORNoticeInfo(qu_id): Observable<any>
  {
    const url = `${this.apiURL}/get-bor-noti-info/${qu_id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public updatecoquotestatus(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/updatequotestatus`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public CPPLDOCUSIGN(id:any,loginRole:any=0): Observable<any>{
    let url = "";  
      url = `${this.apiURL}/cppl-docusign/${id}-${loginRole}`; 
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public sendData(postData: any){
    const url = `${this.apiURL}/import-policies`;
    return this.httpClient.post<any>(url, postData);
  }
  

  printcpplDocuments(type:any = '',id: any,userid: any = '') { 
    let url = "";
    var loggedUserRole = localStorage.getItem('u_ur_id');
    if(type == 'finance'){
        url = `${this.apiURL}/cppl-finance-agreement/${id}-${loggedUserRole}`;
    } else if(type == 'sltwo'){
        url = `${this.apiURL}/cppl-sltwopdf/${id}-${loggedUserRole}/3`;
    } else if(type == 'slone'){
        url = `${this.apiURL}/cppl-slonepdf/${id}-${loggedUserRole}`;
    } else if(type == 'checkList'){
        url = `${this.apiURL}/cppl-checkList/${id}`;
    }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get<any>(url, options);

  }

  public checkQuoteAccess(quoteId: any, userId:any){
    const url = `${this.apiURL}/check-quote-access/${quoteId}-${userId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get<any>(url, options);
  } 
  public FinalapproveCppl(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/finalapprovecpplquote`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public genDailyEndorsementReport(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/gen-web-daily-endorsement`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public getPreviousEndtAmts(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/audit-reverse-amounts`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public WCQuoteRenwalAndCopyQuote(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/wc-quote-renewal-copy-quote`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

}
